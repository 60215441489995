<template>
<div class="main">
  <div class="component-content">

    <TopMenu class="mb-12p">
      <template v-slot:button>
        <button class="btn btn-bg-none btn-close" @click="destroy()">
          <img class="icon-16" src="/static/images/icon/back.svg">
        </button>
      </template>

      <template v-slot:page-name>客户专属</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>
    </TopMenu>

    <div class="content bg-25 p-2 h-100">

        <h3><span class="text-slim">客户全称 </span>{{ customer.name }}</h3>
        <h3><span class="text-slim">客户简称 </span>{{ customer.nickname }}</h3>
        
        <div class="flex-list hr w-100">
          <div style="width:35%;">产品名称</div>
          <div style="width:35%;">专属名称</div>
          <div style="width:20%;">形态</div>
          <div style="width:10%;">默认单价</div>
        </div>



          <div class="flex-list hr w-100" v-for="(item, index) in datas" :key="item.index">
            <div style="width:35%;">{{ datas[index].name }}</div>
            <div style="width:35%;">
              <input type="text" class="form-control p-0 bg-none" placeholder="专属名称" autoComplete="off" v-model="datas[index].new_name">
            </div>
            <div style="width:20%;">{{ datas[index].status }}</div>
            <div style="width:10%;">
              <input type="number" class="form-control p-0 bg-none" placeholder="0.00" autoComplete="off" v-model="datas[index].price">
            </div>
          </div>

      
    </div>

  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'

export default {
  name: 'CustomerAttributes',

  data() {
    return {
      loading: false,
      customer: [],
      datas: [],
      allowAttack: false,
    }
  },

  mounted() {
    console.log(this.$options.name+'挂载')

    if (this.$route.params.id) {
      this.getProductList()
      console.log('修改')
    }
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
  },

  methods: {

    //获取产品列表
    getProductList() {
      this.$axios.get('/api/customer/get-product-name', {
        headers: {
          token: JSON.parse(sessionStorage.Authorization).token
        },
        params: {
          id: this.$route.params.id
        }
      })
      .then(res => {
        if (res.status == 200) {
          if (res.data) {
            this.customer = res.data.customer
            this.datas = res.data.product
          }
        }
      })
      .catch(error => {
      console.log('error:', error)
      })
    },

    destroy() {
      this.$router.push({name: 'CustomerList'})
    },

    save() {
      this.allowAttack = true

      try {

        this.$axios.post('/api/customer/product-name',
          {
            customer_id: this.customer.id,
            product: JSON.stringify(this.datas)
          },
          {
            headers: {
              token: JSON.parse(sessionStorage.Authorization).token
            }
          }
        )
        .then(res => {
          this.allowAttack = false

          if (res.status == 200) {
            console.log(res.data)
            if (res.data.success) {
              this.$toast.success(res.data.message)
            } else {
              this.$toast.error(res.data.message)
            }
            
          }
        })
        .catch(error => {
          this.allowAttack = false
          console.error('error:', error)
        })
      } catch(error) {
        console.error(error)
        this.allowAttack = false
        this.$toast.error(error)
      }
    }

  },

}
</script>

<style scoped>
  
.flex-list {
  padding: 8px;
}
  
</style>
